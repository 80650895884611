import React, { useMemo, useState } from "react";
import "./App.css";

import { Starboard } from "starboard";
// import { Configuration } from "starboard/dist/models/configuration"; // TODO: I need to export this from starboard so I don't have to go digging into the dist directory like this, which seems very wrong

// import { Scene as StarboardScene } from "./models/scene";
// import { Configuration, Artwork } from "./models/configuration";
// import { Vector2, Vector3 } from "three";

function App() {
  const [hash] = useHash();
  const [config, setConfig] = useState<any>(); // Was <Configuration>

  // When the URL hash changes we grab the encoded config data
  React.useEffect(() => {
    const encodedConfig = hash.toString().substr(3);
    if (encodedConfig.length > 0) {
      console.log("trying to parse config");
      const decodedConfig = decodeURIComponent(encodedConfig);
      console.log(decodedConfig);
      const newConfig = JSON.parse(decodedConfig);
      console.log(newConfig);
      setConfig(newConfig);
    }
  }, [hash]);

  const canvasSize = useMemo(() => {
    if (config) {
      let baseLength = config.ultraHighResolution ? 2048 : 1024;

      let width;
      let height;
      let aspectRatio = config.scene.aspectRatio ?? 1;
      let longestEdge;
      const positiveAspectRatio =
        aspectRatio > 1 ? aspectRatio : 1 / aspectRatio;
      longestEdge = baseLength * (1 + (positiveAspectRatio - 1) / 2);

      if (aspectRatio > 1.0) {
        width = longestEdge;
        height = longestEdge / aspectRatio;
      } else {
        width = longestEdge * aspectRatio;
        height = longestEdge;
      }

      return { width, height };
    }
    return { width: 0, height: 0 };
  }, [config]);

  return (
    <div className="App">
      {config ? (
        <div
          style={{
            width: canvasSize.width,
            height: canvasSize.height,
            // width: config.ultraHighResolution ? 2048 : 1024,
            // height: config.ultraHighResolution ? 2048 : 1024,
          }}
        >
          <Starboard
            config={config!}
            captureMode={true}
            ultraHighResolution={config.ultraHighResolution ? true : false}
            allowDrag={false}
            watermarkLevel={0}
            contextWasLostAction={async () => {
              console.log("Goodbye Starboard");
            }}
            onFinishLoading={() => {}}
          />
        </div>
      ) : (
        <p>No config</p>
      )}
    </div>
  );
}

// Memo avoids re-renderering when we don't want to. This is really important for Puppeteer usage because otherwise you can get jobs being "rendered" before they're really ready.
export default React.memo(App);

const useHash = () => {
  console.log("useHash hit");
  const [hash, setHash] = React.useState(() => window.location.hash);

  const hashChangeHandler = React.useCallback(() => {
    if (hash !== window.location.hash) {
      window.status = ""; // Clear window status to signal that the render for the given hash is not ready yet. Possibly should do this elsewhere...
      setHash(window.location.hash);
    }
  }, [hash]);

  React.useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
    };
  }, [hashChangeHandler]);

  const updateHash = React.useCallback(
    (newHash: any) => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash]
  );

  return [hash, updateHash];
};
