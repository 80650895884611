import * as THREE from "three";
import { Box2, MeshStandardMaterial, Vector3 } from "three";

const _plane = new THREE.Mesh(
  new THREE.PlaneGeometry(10, 10),
  new MeshStandardMaterial()
);

const _raycaster = new THREE.Raycaster();

const _pointer = new THREE.Vector2();

const _offset = new THREE.Vector3();

const _intersection = new THREE.Vector3();

const _worldPosition = new THREE.Vector3();

const _inverseMatrix = new THREE.Matrix4();

class HoverControls extends THREE.EventDispatcher {
  constructor(_objects: any, _camera: any, _domElement: any, _callback: any) {
    super();
    _domElement.style.touchAction = "pinch-zoom pan-y";

    const scene = _camera.parent;
    scene.add(_plane);
    _plane.visible = false;
    // _plane.setRotationFromEuler(new THREE.Euler(0, 0, 0));

    const scope = this;

    // this.artworkRotation = new THREE.Euler();

    // function updatePlane(self) {
    //   _plane.position.copy(self.artworkPosition);
    //   _plane.rotation.copy(self.artworkRotation);
    // }

    function activate(self: any) {
      console.log("activate");

      let initialX = 0;
      let initialY = 0;

      let verticalMovementDetermined = false;

      _domElement.addEventListener("touchstart", function (e: any) {
        initialX = e.touches[0].screenX;
        initialY = e.touches[0].screenY;
        console.log("initialX: " + initialY);

        verticalMovementDetermined = false;

        deactivate(); // Crude way to disable pointer events when touch events detected...
      });

      _domElement.addEventListener("touchmove", function (e: any) {
        console.log("screenX: " + e.touches[0].screenX);

        if (verticalMovementDetermined) {
          _callback(new THREE.Vector2(0, 0));
          return;
        }

        if (Math.abs(e.touches[0].screenY - initialY) > 200) {
          console.log("Vertical movement determined");
          verticalMovementDetermined = true;
        } else if (Math.abs(e.touches[0].screenX - initialX) > 10) {
          console.log("Horizontal movement determined");
          e.preventDefault();
        }

        const rect = _domElement.getBoundingClientRect();
        let x = ((e.touches[0].clientX - rect.left) / rect.width) * 2 - 1;
        // let y = ((event.clientY - rect.top) / rect.height) * 2 - 1;

        _callback(new THREE.Vector2(x, 0));
      });

      _domElement.addEventListener("touchcancel", function (e: any) {
        console.log("touchcancel");
        _callback(new THREE.Vector2(0, 0));
      });
      _domElement.addEventListener("touchend", function (e: any) {
        console.log("touchcancel");
        _callback(new THREE.Vector2(0, 0));
      });

      _domElement.addEventListener("pointermove", onPointerMove.bind(self));

      // _domElement.addEventListener("pointerdown", onPointerDown.bind(self));

      // _domElement.addEventListener("pointerup", onPointerCancel.bind(self));

      _domElement.addEventListener("pointerleave", onPointerCancel.bind(self));
    }

    function deactivate() {
      _domElement.removeEventListener("pointermove", onPointerMove.bind(self));

      // _domElement.removeEventListener("pointerdown", onPointerDown.bind(self));

      // _domElement.removeEventListener("pointerup", onPointerCancel.bind(self));

      _domElement.removeEventListener(
        "pointerleave",
        onPointerCancel.bind(self)
      );

      _domElement.style.cursor = "pointer";
    }

    function dispose() {
      deactivate();
    }

    function getRaycaster() {
      return _raycaster;
    }

    function onPointerMove(event: any) {
      const rect = _domElement.getBoundingClientRect();
      let x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      let y = ((event.clientY - rect.top) / rect.height) * 2 - 1;

      _callback(new THREE.Vector2(x, y));

      // console.log(`${x} x ${y}`);
      return;
    }

    // function onPointerDown(event) {
    //   updatePlane(this);

    //   if (scope.enabled === false) return;
    //   updatePointer(event);
    //   _intersections.length = 0;

    //   _raycaster.setFromCamera(_pointer, _camera);

    //   _raycaster.intersectObjects(scope.objects, true, _intersections);

    //   if (_intersections.length > 0) {
    //     _selected =
    //       scope.transformGroup === true
    //         ? scope.objects[0]
    //         : _intersections[0].object;
    //     _isSelected(!!_selected);

    //     const hits = _raycaster.intersectObject(_plane, false);
    //     if (hits.length > 0) {
    //       _inverseMatrix.copy(_selected.parent.matrixWorld).invert();

    //       _intersection.copy(hits[0].point);

    //       _offset
    //         .copy(_intersection)
    //         .sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
    //     }

    //     // if (_raycaster.ray.intersectBox(_box, _intersection)) {
    //     //   _inverseMatrix.copy(_selected.parent.matrixWorld).invert();

    //     //   _offset
    //     //     .copy(_intersection)
    //     //     .sub(_worldPosition.setFromMatrixPosition(_selected.matrixWorld));
    //     // }

    //     _domElement.style.cursor = "move";
    //     scope.dispatchEvent({
    //       type: "dragstart",
    //       object: _selected,
    //     });
    //   }
    // }

    function onPointerCancel() {
      _callback(new THREE.Vector2(0, 0));

      return;

      // if (scope.enabled === false) return;

      // if (_selected) {
      //   scope.dispatchEvent({
      //     type: "dragend",
      //     object: _selected,
      //   });
      //   _selected = null;
      //   _isSelected(false);
      // }

      // _domElement.style.cursor = _hovered ? "pointer" : "auto";
    }

    // function updatePointer(event) {
    //   const rect = _domElement.getBoundingClientRect();

    //   _pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    //   _pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;
    // }

    activate(this);

    // this.enabled = true;
    // this.transformGroup = true;
    // this.activate = activate;
    // this.deactivate = deactivate;
    // this.dispose = dispose;
    // this.getObjects = getObjects;
    // this.getRaycaster = getRaycaster;
    // this.updatePlane = updatePlane;
  }
}

export { HoverControls };
